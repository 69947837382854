var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "grey lighten-3", attrs: { flat: "" } },
            [
              _c("v-icon", { staticClass: "mx-2" }, [
                _vm._v(" mdi-tray-arrow-up ")
              ]),
              _c("v-toolbar-title", [_vm._v(" Contract Upload ")]),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { inset: "", vertical: "" }
              }),
              _c("ContractFilters", {
                attrs: { loading: _vm.loading },
                on: { contractFiltersInput: _vm.handleFilterInput },
                model: {
                  value: _vm.searchParams,
                  callback: function($$v) {
                    _vm.searchParams = $$v
                  },
                  expression: "searchParams"
                }
              }),
              _c("v-spacer"),
              _vm.tenant === "alliance-retail-group" && _vm.isAdmin
                ? _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "main" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.exceptionModal = true
                            }
                          }
                        },
                        [
                          _vm._v(" Exception Reports "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-download")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      color: "accent",
                                      small: "",
                                      fab: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.uploadModal = true
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-file-upload-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_vm._v(" Upload contract ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          color: "#fff",
          "loading-text": "Loading... Please wait",
          headers: _vm.headers,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          items: _vm.uploads,
          loading: _vm.loading,
          "hide-default-footer": "",
          "fixed-header": "",
          dense: ""
        },
        on: {
          "update:items": function($event) {
            _vm.uploads = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.upload_status",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.upload_status === "COMPLETED_WITH_WARNINGS"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              dark: "",
                                              color: item.status_color
                                            }
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(" COMPLETED "),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              right: "",
                                              dark: ""
                                            }
                                          },
                                          [_vm._v("mdi-alert-circle-outline")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_vm._v(" Completed with Warnings ")]
                      )
                    : item.upload_status === "PENDING_WITH_WARNINGS"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              dark: "",
                                              color: item.status_color
                                            }
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              "min-width": "70px",
                                              "padding-left": "4px"
                                            }
                                          },
                                          [_vm._v(" PENDING")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              right: "",
                                              dark: ""
                                            }
                                          },
                                          [_vm._v("mdi-alert-circle-outline")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_vm._v(" Pending with Warnings ")]
                      )
                    : _c(
                        "v-chip",
                        {
                          attrs: {
                            small: "",
                            dark: "",
                            color: item.status_color
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "min-width": "86px"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.upload_status) + " ")]
                          )
                        ]
                      )
                ]
              }
            },
            {
              key: "item.updated_on",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticClass: "primary--text",
                                      staticStyle: { cursor: "pointer" }
                                    },
                                    on
                                  ),
                                  [_vm._v(" " + _vm._s(item.updated_on) + " ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" Updated by " + _vm._s(item.updated_by) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.errors",
              fn: function(ref) {
                var item = ref.item
                return [
                  item &&
                  (item.warning_reason ||
                    item.failure_reason ||
                    item.has_exception)
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: item.failure_reason
                                                    ? "error"
                                                    : "amber darken-2"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openErrorsModal(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-alert-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.failure_reason
                                      ? "View Errors"
                                      : "View Warnings"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c("span", [_vm._v("-")])
                ]
              }
            },
            {
              key: "item.promo_ids",
              fn: function(ref) {
                var item = ref.item
                return [
                  item &&
                  Array.isArray(item.promo_ids) &&
                  ["COMPLETED", "COMPLETED_WITH_WARNINGS"].includes(
                    item.upload_status
                  )
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPromosModal(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-view-list")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_vm._v(" View Promos Created by Upload ")]
                          )
                        ],
                        1
                      )
                    : _c("span", [_vm._v("-")])
                ]
              }
            },
            {
              key: "item.upload_id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.copyID(item.upload_id)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-information")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(item.upload_id))])]
                  )
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("ContractActions", {
                    attrs: { selectedContract: item },
                    on: { refresh: _vm.getUploads }
                  })
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("PagingFooter", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      pageSize: _vm.pageSize,
                      page: _vm.page,
                      disablePrevious: _vm.disablePrevious,
                      disableNext: _vm.disableNext,
                      showFirstPage: _vm.showFirstPage
                    },
                    on: {
                      "update:pageSize": function($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function($event) {
                        _vm.pageSize = $event
                      },
                      first: _vm.setFirstPage,
                      previous: _vm.previous,
                      next: _vm.next
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm.promosModal
        ? _c("ContractPromosModal", {
            attrs: { selectedContract: _vm.selectedContract },
            on: { closePromos: _vm.closePromosModal },
            model: {
              value: _vm.promosModal,
              callback: function($$v) {
                _vm.promosModal = $$v
              },
              expression: "promosModal"
            }
          })
        : _vm._e(),
      _vm.errorsModal
        ? _c("ContractErrorsModal", {
            attrs: { selectedContract: _vm.selectedContract },
            on: { closeErrors: _vm.closeErrorsModal },
            model: {
              value: _vm.errorsModal,
              callback: function($$v) {
                _vm.errorsModal = $$v
              },
              expression: "errorsModal"
            }
          })
        : _vm._e(),
      _vm.uploadModal
        ? _c("ContractUploadModal", {
            on: { closeUpload: _vm.closeUploadModal },
            model: {
              value: _vm.uploadModal,
              callback: function($$v) {
                _vm.uploadModal = $$v
              },
              expression: "uploadModal"
            }
          })
        : _vm._e(),
      _vm.exceptionModal
        ? _c("ExceptionReportModal", {
            on: { closeExceptionModal: _vm.closeExceptionModal },
            model: {
              value: _vm.exceptionModal,
              callback: function($$v) {
                _vm.exceptionModal = $$v
              },
              expression: "exceptionModal"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
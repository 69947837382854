<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row dense align="center">
      <v-toolbar flat class="grey lighten-3">
        <v-icon class="mx-2">
          mdi-tray-arrow-up
        </v-icon>
        <v-toolbar-title>
          Contract Upload
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <ContractFilters
          v-model="searchParams"
          :loading="loading"
          @contractFiltersInput="handleFilterInput"
        />
        <v-spacer/>
        <v-col cols="auto" class="ml-auto" v-if="tenant === 'alliance-retail-group' && isAdmin">
          <v-btn
            class="white--text"
            color="main"
            @click.stop="exceptionModal = true">
            Exception Reports
            <v-icon right>mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="accent"
                small
                fab
                @click.stop="uploadModal = true">
                <v-icon>mdi-file-upload-outline</v-icon>
              </v-btn>
            </template>
            Upload contract
          </v-tooltip>
        </v-col>
      </v-toolbar>
    </v-row>
    <v-data-table
      color="#fff"
      loading-text="Loading... Please wait"
      :headers="headers"
      :height="tableSize"
      :items-per-page="pageSize"
      :items.sync="uploads"
      :loading="loading"
      hide-default-footer
      fixed-header
      dense>

      <template v-slot:[`item.upload_status`]="{ item }">
          <v-tooltip top v-if="item.upload_status === 'COMPLETED_WITH_WARNINGS'">
            <template v-slot:activator="{ on, attrs }">
              <v-chip 
                v-on="on"
                v-bind="attrs"
                small dark 
                :color="item.status_color">
                COMPLETED <v-icon small right dark>mdi-alert-circle-outline</v-icon>
              </v-chip>
            </template>
            Completed with Warnings
          </v-tooltip>
          <v-tooltip top v-else-if="item.upload_status === 'PENDING_WITH_WARNINGS'">
            <template v-slot:activator="{ on, attrs }">
              <v-chip 
                v-on="on"
                v-bind="attrs"
                small dark
                :color="item.status_color">
                <span style="display: inline-block; min-width: 70px; padding-left: 4px">
                  PENDING</span>
                  <v-icon small right dark>mdi-alert-circle-outline</v-icon>
              </v-chip>
            </template>
            Pending with Warnings
          </v-tooltip>
          <v-chip 
            v-else 
            small dark 
            :color="item.status_color">
            <span style="display: inline-block; min-width: 86px">
              {{ item.upload_status }}
            </span>
          </v-chip>
      </template>

      <template v-slot:[`item.updated_on`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="primary--text" style="cursor: pointer">
              {{ item.updated_on }}
            </span>
          </template>
          Updated by {{ item.updated_by }}
        </v-tooltip>
      </template>

      <template v-slot:[`item.errors`]="{ item }">
        <span v-if="item && (item.warning_reason || item.failure_reason || item.has_exception)">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon 
                :color="item.failure_reason ? 'error' : 'amber darken-2'"
                @click.stop="openErrorsModal(item)">
                <v-icon>mdi-alert-outline</v-icon>
              </v-btn>
            </template>
            {{ item.failure_reason ? 'View Errors' : 'View Warnings' }}
          </v-tooltip>
        </span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.promo_ids`]="{ item }">
        <span v-if="item && Array.isArray(item.promo_ids) && ['COMPLETED', 'COMPLETED_WITH_WARNINGS'].includes(item.upload_status)">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="openPromosModal(item)">
                <v-icon>mdi-view-list</v-icon>
              </v-btn>
            </template>
            View Promos Created by Upload
          </v-tooltip>
        </span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.upload_id`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click.stop="copyID(item.upload_id)">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{ item.upload_id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
         <ContractActions :selectedContract="item" @refresh="getUploads"/>
      </template>

      <template v-slot:footer>
        <PagingFooter
          :pageSizes="pageSizes"
          :pageSize.sync="pageSize"
          :page="page"
          :disablePrevious="disablePrevious"
          :disableNext="disableNext"
          :showFirstPage="showFirstPage"
          @first="setFirstPage"
          @previous="previous"
          @next="next">
        </PagingFooter>
      </template>
    </v-data-table>

    <ContractPromosModal
      v-if="promosModal"
      v-model="promosModal"
      :selectedContract="selectedContract"
      @closePromos="closePromosModal"
    />

    <ContractErrorsModal
      v-if="errorsModal"
      v-model="errorsModal"
      :selectedContract="selectedContract"
      @closeErrors="closeErrorsModal"
    />

    <ContractUploadModal
      v-if="uploadModal"
      v-model="uploadModal"
      @closeUpload="closeUploadModal"
    />
    <ExceptionReportModal
      v-if="exceptionModal"
      v-model="exceptionModal"
      @closeExceptionModal="closeExceptionModal"
    />
  </v-container>
</template>
<script>
// API
import ContractUpload from '@/axios/contract-upload-endpoint.js'
// Mixins
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
// Components
import PagingFooter from '@/components/shared/PagingFooter'
const ContractUploadModal = () => import('@/components/contracts/ContractUploadModal.vue')
const ContractPromosModal = () => import('@/components/contracts/ContractPromosModal.vue')
const ContractErrorsModal = () => import('@/components/contracts/ContractErrorsModal.vue')
const ContractActions = () => import('@/components/contracts/ContractActions.vue')
const ContractFilters = () => import('@/components/contracts/ContractFilters.vue')
const ExceptionReportModal = () => import('@/components/contracts/ExceptionReportModal.vue')

export default {
  name: 'ContractUpload',
  data () {
    return {
      loading: false,
      uploadModal: false,
      promosModal: false,
      errorsModal: false,
      exceptionModal: false,
      searchParams: {},
      selectedContract: {},
      uploads: [],
      tableSize: '',
      windowHeight: '',
      page: 1,
      timer: '',
      headerProps: {
        sortable: true, filterable: false, class: 'accent white--text'
      },
      baseHeaders: [
        { sortable: false },
        { text: 'File Name', value: 'file_name' },
        { text: 'Status', value: 'upload_status', align: 'center' },
        { text: 'Warnings / Errors', value: 'errors', align: 'center', sortable: false },
        { text: 'Submitted By', value: 'audit.created_by' },
        { text: 'Submitted On', value: 'created_on' },
        { text: 'Last Updated', value: 'updated_on' },
        { text: 'Runtime', value: 'run_time',  align: 'center' },
        { text: 'Promos', value: 'promo_ids',  align: 'center', sortable: false },
        { text: 'Id', value: 'upload_id', align: 'center', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ]
    }
  },
  components: { PagingFooter, ContractUploadModal, ContractPromosModal, ContractErrorsModal, ContractActions, ContractFilters, ExceptionReportModal },
  mixins: [fullWidthTable, displayAlert, userAccess],
  async created () {
    this.nonTableHeight = 220.5
    if (this.limitAccessByUserParties && this.userRelatedPartyIds.length === 0) {
      await this.$store.dispatch('getUserRelatedParties')
    }
    await this.getUploads()
    this.timer = setInterval(this.refresh, 15000);
    if (this.$store.getters.promo_categories.length === 0) {
      await this.$store.dispatch('getPromoCategories')
    }
    await this.$store.dispatch('getResponsibleParties')
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  watch: {
    page: {
      handler(newValue) {
        if (newValue) this.getUploads()
      }
    }
  },
  computed: {
    headers() {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    disablePrevious () {
      return (this.page - 1) === 0
    },
    disableNext () {
      return this.loading || (this.uploads.length < this.pageSize)
    },
    showFirstPage () {
      return this.page > 1
    },
    offset () {
      return (this.page - 1) * this.pageSize
    },
    isModalActive () {
      return this.errorsModal || this.promosModal || this.uploadModal
    }
  },
  methods: {
    async getUploads(refresh = false) {
      if (!refresh) {
        this.loading = true
      }
      let uploads = []
      try {
        let params = {...this.searchParams}
        if (this.limitAccessByRelatedVendors) {
          params.created_by = this.user.email
        }
        const { data } = await ContractUpload.getList(params, this.offset, this.pageSize)
        if (data?.length > 0) {
          uploads = data.map(upload => this.formatUploadRecord(upload))
        }
        this.uploads = uploads
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loading = false
      }
    },
    refresh () {
      if (!this.isModalActive) {
        this.getUploads(true)
      }
    },
    openPromosModal (contract) {
      this.promosModal = true
      this.selectedContract = contract
    },
    openErrorsModal(contract) {
      this.errorsModal = true
      this.selectedContract = contract
    },
    formatUploadRecord (upload) {
      const { audit, run_time } = upload
      upload.created_on = this.$config.formatTimestamp(audit.created_on)
      upload.updated_on = this.$config.formatTimestamp(audit.updated_on)
      upload.updated_by = audit.updated_by
      upload.run_time = (!run_time || run_time === 'None')
        ? '-'
        : this.formatTimeString(run_time)
      upload.status_color = this.getStatusColor(upload.upload_status)
      return upload
    },
    handleFilterInput(newValue) {
      this.searchParams = newValue

      return (this.page > 1) ? this.page = 1 : this.getUploads()
    },
    getStatusColor (status) {
      switch (status.toLowerCase()) {
        case 'pending':
          return 'black'
        case 'pending_with_warnings':
          return 'grey darken-1'
        case 'running':
          return 'blue'
        case 'completed':
          return 'green'
        case 'completed_with_warnings':
          return 'amber darken-2'
        case 'failed':
          return 'red'
        case 'exception':
          return 'red darken-4'
        case 'cancelled':
          return 'grey lighten-1'
      }
    },
    closeUploadModal (uploaded = false) {
      this.uploadModal = false
      if (uploaded) {
        this.refresh()
      }
    },
    closeExceptionModal () {
      this.exceptionModal = false
    },
    closePromosModal () { 
      this.promosModal = false
    },
    closeErrorsModal () {
      this.errorsModal = false
    },
    previous () {
      this.page -= 1
      this.getUploads()
    },
    next () {
      this.page += 1
      this.getUploads()
    },
    setFirstPage () {
      this.page = 1
      this.getUploads()
    },
    copyID (id) {
      navigator.clipboard.writeText(id)
    },
    formatTimeString (time) {
      return time.split('.')[0]
    }
  }
}
</script>
<style>
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}
</style>